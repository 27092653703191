<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
        :rules="rules"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-input
                v-model="formData.userId"
                clearable
                placeholder="用户ID"
                @keyup.enter.native="handleSearchList"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.nationCode"
                clearable
                filterable
                collapse-tags
                value-key="countryCode"
                placeholder="国家"
              >
                <el-option
                  v-for="item in nationList"
                  :key="item.countryCode"
                  :label="item.nation"
                  :value="item.countryCode"
                />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-select
                v-model="formData.rechargeTimesType"
                placeholder="充值次数"
                clearable
              >
                <el-option
                  v-for="(item, index) in rechargeCountList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.vipStatus"
                placeholder="会员状态"
                clearable
              >
                <el-option
                  v-for="(item, index) in memberTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="上次消费时间">
              <el-date-picker
                v-model="datetimerange"
                type="datetimerange"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.cancellationStatus"
                placeholder="用户状态"
                clearable
              >
                <el-option
                  v-for="(item, index) in cancellationStatusList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.channel"
                clearable
                filterable
                collapse-tags
                placeholder="用户登录端"
              >
                <el-option
                  v-for="item in clientList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div style="display: inline-block">
              <el-form-item
                label="累计消费金额"
                prop="consumeAmountStart"
              >
                <el-input-number
                  v-model="formData.consumeAmountStart"
                  :min="0"
                />
              </el-form-item>
              <el-form-item
                label="至"
                label-width="30px"
                prop="consumeAmountEnd"
              >
                <el-input-number
                  v-model="formData.consumeAmountEnd"
                  :min="0"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="formData.email"
                  clearable
                  placeholder="请输入邮箱"
                />
              </el-form-item>
              <el-form-item>
                <el-input
                  v-model="formData.userName"
                  clearable
                  placeholder="请输入昵称或手机号"
                />
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="注册时间">
              <el-date-picker
                v-model="datetimerange2"
                type="datetimerange"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleSearchList"
        >
          搜索
        </el-button>
      </template>
    </BHeader>
    <el-table
      ref="table"
      v-loading="orderListInfo.loading"
      :data="orderListInfo.list"
      stripe
      class="data-table"
      @expand-change="handleExpandChange"
    >
      <el-table-column type="expand">
        <template #default="{row: { userIdStr: userId}}">
          <el-form
            v-loading="userInfo[userId].loading"
            :inline="true"
            class="user-details-info"
          >
            <h3>基础信息</h3>
            <el-row>
              <el-col :span="24">
                <el-form-item label="userId:">
                  {{ user(userId).userIdStr }}
                </el-form-item>
                <el-form-item label="用户名:">
                  {{ user(userId).nickName }}
                </el-form-item>
                <el-form-item label="邮箱:">
                  {{ user(userId).email }}
                </el-form-item>
                <el-form-item label="注册时间:">
                  {{ user(userId).createTime }}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="登录端:">
                  {{ user(userId).platform }}
                </el-form-item>
                <el-form-item label="登录国家:">
                  {{ user(userId).nation }}
                </el-form-item>
                <el-form-item label="登录区域:">
                  {{ user(userId).zoneName || '-' }}
                </el-form-item>
                <el-form-item label="登录IP:">
                  {{ user(userId).RegisterIp || '-' }}
                </el-form-item>
              </el-col>
            </el-row>

            <h3>消费信息</h3>
            <el-row>
              <el-col :span="24">
                <el-form-item label="累计消费金额:">
                  {{ user(userId).PayTotalMoney }}
                </el-form-item>
                <el-form-item label="累计消费次数:">
                  {{ user(userId).payCount }}
                </el-form-item>
                <el-form-item label="上一次消费时间:">
                  {{ user(userId).lastConsumeTime || '-' }}
                </el-form-item>
              </el-col>
            </el-row>

            <h3>时长信息</h3>
            <el-row>
              <el-col :span="24">
                <el-form-item label="剩余时长:">
                  {{ user(userId).totalTime | secondToHourFilter }}
                </el-form-item>
                <el-form-item label="订阅剩余时长:">
                  {{ user(userId).subscriptionTime | secondToHourFilter }}
                </el-form-item>
                <el-form-item label="时长卡:">
                  {{ user(userId).topupTime | secondToHourFilter }}
                </el-form-item>
                <el-form-item label="金币:">
                  {{ user(userId).goldNum }}个
                </el-form-item>
              </el-col>
            </el-row>

            <h3>订阅信息</h3>
            <el-row>
              <el-col :span="24">
                <template v-if="user(userId).isSubscription">
                  <el-form-item label="订阅时长:">
                    {{ user(userId).subscriptionGoodsTime || '-' }}
                  </el-form-item>
                  <el-form-item label="订阅类型:">
                    {{ user(userId).subscriptionTypeDesc || '-' }}
                  </el-form-item>
                  <el-form-item label="订阅开始时间:">
                    {{ user(userId).subscriptionStartTime }}
                  </el-form-item>
                  <el-form-item label="订阅结束时间:">
                    {{ user(userId).subscriptionEndTime }}
                  </el-form-item>
                </template>
                <template v-else>
                  未订阅
                </template>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column
        prop="userIdStr"
        label="用户ID"
        min-width="180"
      />
      <el-table-column
        prop="nickName"
        label="昵称"
        min-width="100"
      />
      <el-table-column
        prop="registerIp"
        label="IP"
        min-width="130"
      />
      <el-table-column
        prop="channel"
        label="客户端"
        min-width="100"
      >
        <template v-slot="{row}">
          <span
            v-for="item in clientList"
            :key="item.value"
          >
            <el-tag
              v-if="item.value == row.channel"
              size="small"
            >
              {{ item.name }}
            </el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        min-width="130"
      />
      <el-table-column
        prop="nation"
        label="国家"
        min-width="100"
      />
      <el-table-column
        prop="surplusTime"
        label="剩余时长"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ row.surplusTime | secondToHourFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="vipStatus"
        label="会员状态"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ row.vipStatus | memberTypeFilters }}
        </template>
      </el-table-column>
      <el-table-column
        prop="rechargeTimes"
        label="充值次数"
        min-width="100"
      />
      <el-table-column
        prop="consumeAmount"
        label="累计消费金额"
        min-width="120"
      >
        <template v-slot="{row}">
          {{ row.consumeAmount / DIVISOR.TRUE_AMOUNT }} USD
        </template>
      </el-table-column>
      <el-table-column
        prop="lastConsumeTime"
        label="上次消费时间"
        width="160"
      />
      <el-table-column
        prop="cancellationStatus"
        label="用户状态"
        width="120"
      >
        <template #default="{row}">
          <el-button
            v-if="row.cancellationStatus === 1"
            type="text"
          >
            正常
          </el-button>
          <template v-if="row.cancellationStatus === 2">
            <el-button
              type="text"
              style="margin-right: 20px;"
            >
              已注销
            </el-button>
            <span>{{ row.cancellationTime }}</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="注册时间"
        width="160"
      />
      <el-table-column
        prop="status"
        label="账号管理"
        width="160"
        fixed="right"
      >
        <template #default="{row}">
          <!-- 默认可能没值,且没值表示启用状态, 因此互换active/inactive的表示 -->
          <!-- 因此, status 2表示禁用 1表示启用 -->
          <el-switch
            :value="row.status"
            active-color="#ff4949"
            inactive-color="#13ce66"
            :active-value="2"
            :inactive-value="1"
            active-text="禁用"
            inactive-text="启用"
            @change="toggleUserDisabled(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="orderListInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.size"
      @pagination="queryOrderList(formData)"
    />
  </Layout>
</template>

<script>
import {
  rechargeCountList,
  memberTypeList,
  memberTypeMap
} from '@/utils/selectOptions'
import {
  queryUserList,
  queryUserInfo
} from '@/api/customer'
import { mapState } from 'vuex'

import { DIVISOR } from '@/enum/index.js'
import { userDisabled } from '@/api/user.js'
import { cloneDeep } from 'lodash'
import {
  getUserNationStatistics
} from '@/api/electronicEntity.js'

import { round } from '@/utils'

const cancellationStatusList = [
  { value: 0, name: '全部' },
  { value: 1, name: '正常' },
  { value: 2, name: '已注销' }
]

const clientList = [
  { value: 0, name: '全部' },
  { value: 1, name: 'Android' },
  { value: 2, name: 'IOS' },
  { value: 3, name: 'WEB' },
  { value: 4, name: 'H5' },
  { value: 5, name: 'VR' }
]

const vipLevelMap = {
  0: '未知',
  1: '月会员',
  2: '季会员',
  3: '年会员'
}

export default {
  name: 'UserList',
  filters: {
    memberTypeFilters (status) {
      return memberTypeMap[status] ? memberTypeMap[status].name : '未知'
    },
    surplusTimeFilter (val) {
      const times = new Date(val * 1000)
      const h = Math.floor(times.getTime() / (60 * 60 * 1000))
      const H = h > 9 ? h : '0' + h
      const M = ('0' + times.getMinutes()).slice(-2)
      const S = ('0' + times.getSeconds()).slice(-2)
      return `${H}:${M}:${S}`
    },
    secondToHourFilter (val) {
      return round(val / 60 / 60, -1) + 'h'
    },
    limitTimeTypeFilter (limitTimeType) {
      const map = { 1: 'day', 2: 'month', 3: 'year' }
      return map[limitTimeType] || limitTimeType
    }
  },
  data () {
    const validateConsumeAmountStart = (rule, value, callback) => {
      if (typeof this.formData.consumeAmountEnd !== 'undefined') {
        if (typeof value === 'undefined') {
          callback(new Error('请输入起始金额'))
        } else {
          this.$refs.form.validateField('consumeAmountEnd')
          callback()
        }
      } else {
        callback()
      }
    }
    const validateConsumeAmountEnd = (rule, value, callback) => {
      if (typeof this.formData.consumeAmountStart !== 'undefined') {
        if (typeof value === 'undefined') {
          callback(new Error('请输入结束金额'))
        } else if (value <= this.formData.consumeAmountStart) {
          callback(new Error('结束金额必须大于起始金额'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      vipLevelMap,
      memberTypeMap,
      clientList,
      rechargeCountList,
      memberTypeList,
      cancellationStatusList,
      formData: {
        page: 1,
        size: 20
      },
      datetimerange: [],
      datetimerange2: [],
      nationList: [],
      orderListInfo: {
        loading: false,
        total: 0,
        list: []
      },
      userInfo: {

      },
      rules: {
        consumeAmountStart: [
          { validator: validateConsumeAmountStart, trigger: 'blur' }
        ],
        consumeAmountEnd: [
          { validator: validateConsumeAmountEnd, trigger: 'blur' }
        ]
      },
      DIVISOR
    }
  },
  computed: {
    ...mapState('app', ['channel', 'appId'])
  },
  mounted () {
    this.queryOrderList()
    this.queryNationList()
  },
  methods: {
    user (userId) {
      return this.userInfo[userId]?.data || {}
    },
    handleExpandChange (row, expandedRows) {
      const userInfo = this.userInfo
      expandedRows.forEach(item => {
        const userId = item.userIdStr
        if (!userInfo[userId] && !userInfo?.[userId]?.loading) {
          this.$set(userInfo, userId, {})
          this.$set(userInfo[userId], 'loading', true)
          queryUserInfo({ userId, appId: 20 })
            .then(res => {
              if (res.code === 200) {
                this.$set(userInfo[userId], 'data', res.data)
              }
            })
            .finally(() => {
              userInfo[userId].loading = false
            })
        }
      })
      return true
    },
    queryNationList () {
      return getUserNationStatistics({
        pageNum: 1,
        pageSize: 1000
      }).then(res => {
        if (res.code === 200) {
          const sortNationList = (res.data.list || []).sort((a, b) => {
            if (a.nation < b.nation) {
              return -1
            }
            if (a.nation > b.nation) {
              return 1
            }
            return 0
          })
          this.nationList = sortNationList
        }
      })
    },
    handleSearchList () {
      this.$refs.form.validate().then(valid => {
        if (valid) {
          this.formData.page = 1
          this.queryOrderList()
        }
      })
    },
    queryOrderList () {
      this.$refs.form.validate().then(valid => {
        if (valid) {
          this.orderListInfo.loading = true
          let lastConsumeTimeStart
          let lastConsumeTimeEnd
          let loginTimeStart
          let loginTimeEnd
          if (this.datetimerange && this.datetimerange.length) {
            lastConsumeTimeStart = this.datetimerange[0]
            lastConsumeTimeEnd = this.datetimerange[1]
          }

          if (this.datetimerange2 && this.datetimerange2.length) {
            loginTimeStart = this.datetimerange2[0]
            loginTimeEnd = this.datetimerange2[1]
          }
          const formData = cloneDeep(this.formData)
          if (!formData.cancellationStatus) {
            formData.cancellationStatus = 0
          }
          queryUserList({
            ...formData,
            lastConsumeTimeStart,
            lastConsumeTimeEnd,
            loginTimeStart,
            loginTimeEnd,
            appId: 20
          })
            .then(res => {
              this.orderListInfo.list = res.data.list || []
              this.orderListInfo.total = res.data.total
              this.userInfo = {}
            })
            .finally(() => {
              this.orderListInfo.loading = false
            })
        }
      })
    },
    toggleUserDisabled (row) {
      const isON = row.status !== 2
      let tips = ''
      if (isON) {
        tips = `此操作将禁用账号:${row.userIdStr}, 是否继续`
      } else {
        tips = `此操作将启用账号:${row.userIdStr}, 是否继续`
      }
      this.$confirm(tips, '提示')
        .then(() => {
          userDisabled({
            userId: row.userIdStr,
            status: isON ? 2 : 1
          })
            .then(res => {
              if (res.code === 200) {
                this.$set(row, 'status', isON ? 2 : 1)
              }
            })
        })
    }
  }
}
</script>

<style lang="less">
  .user-details-info {
    h3 {
      font-size: 14px;
      margin: 24px 0 0;
      color: #000;
    }
  }
  .data-table {
    .el-form-item {
      margin-bottom: 0;
      min-width: 220px;
      .el-form-item__label {
        color: #666;
        font-weight: bold;
      }
    }
  }
</style>
